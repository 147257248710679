<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container mt-5">
  
            <div class="row w-100 mb-4">
              <div class="col-12 pl-3 px-0 mb-0 text-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/smartlodge/admin/' }">Home</el-breadcrumb-item>
                  <el-breadcrumb-item>Newsletters</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
  
            <h3><b>Newsletters:</b></h3>
            <div
              style="display: flex; align-items:center; justify-content: space-between;"
              class="search_add_section"
            >
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by email"
                  class="search_by_input form-control"
                  spellcheck="off"
                />
              </div>
            </div>
            <hr />
  
            <div
              v-loading="loading"
              class="table-responsive mb-5"
            >
              <data-tables
                :data="newsletters"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
  
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getNewsletters"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load Newsletters Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No Newsletters</div>
                </div>
  
                <el-table-column
                  label="#"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="80px"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Newsletter"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.email }} </span><br />
                    <small>Add On: {{ formattedDate(scope.row.created_at) }} </small><br />
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Actions"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex; align-items:center; justify-content:center;">
                      <el-tooltip
                        content="Delete"
                        placement="top"
                      >
                        <el-button
                          type="danger"
                          :disabled="false"
                          @click="deleteNewsletter(scope.row.newsletters_id)"
                          icon="el-icon-delete"
                          circle
                        ></el-button>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>
  
              </data-tables>
            </div>
  
          </div>
        </article>
      </section>
  
    </div>
  </template>
  
  <script>
  import ScaleOut from "@/components/backoffice/scale-out-component.vue";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut,
    },
  
    data() {
      return {
        loading: false,
        isLoading: false,
        newsletters: [],
        pickupLocation_data: {},
        decoded_token: {},
        decoded_token2: {},
        first_name: "",
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
  
        filters: [
          {
            prop: "fullname",
            value: "",
          },
        ],
        loadingError: false,
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getNewsletters();
    },
  
    methods: {
  
      async getNewsletters() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`newsletters`);
          if (request.data.success) {
            this.newsletters = request.data.newsletters;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch newsletters now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async deleteNewsletter(newsletterId) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this newsletter. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`newsletters/${newsletterId}`);
              if (
                request.data.success &&
                request.data.message === "NEWSLETTER_DELETED_SUCCESSFULLY"
              ) {
                this.getNewsletters();
                this.$notify({
                  title: "Success",
                  message: "Newsletter deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete the Newsletter, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
    },
  };
  </script>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>